import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import Header from "./components/Header/Header";
import Pages from "./pages/Pages";
import './App.css';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Header />
                <Pages />
            </BrowserRouter>
        )
    }
}
export default App;
