import React, { Component } from 'react';
import "./loading.css";
import ipImg from "../../../public/resources/imgs/ip.png"

class Loading extends Component {
    render() {
        return (
            <div>
                <img src={ipImg} alt="icon" id="lodingPic"></img>
            </div>
        )
    }
}

export default Loading;
